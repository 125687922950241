<div class="product-content">
 <div class="product-content__gallery">
   <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
 </div>
 <div class="product-content__description">
   <ul class="product-content__description-card">
     <li class="product-content__description-item product-content__description-item--model">Renault Magnum 450 DXI euro 5 Мega</li>
     <li class="product-content__description-item product-content__description-item--price">Ціна 17500 $</li>
     <li class="product-content__description-item">Характеристики</li>
     <li class="product-content__description-item">Рік випуску:  2009р </li>
     <li class="product-content__description-item">Пробіг:  634700 км
     </li>
     <li class="product-content__description-item">Паливо:  дизель
     </li>
     <li class="product-content__description-item">Тип КПП:  ручна/механіка
     </li>
     <li class="product-content__description-item">Шини:  315/60 R 22.5/295х60х22.5 , залишок 40</li>
     <li class="product-content__description-item">Баки:  2шт (300л, 350л )</li>
     <li class="product-content__description-item">Комфорт:  2 спальні місця , люк,ел.склопідйомник
     </li>
     <li class="product-content__description-item">Мультимедія:  CD
     </li>
     <li class="product-content__description-item">Безпека:  abs, гірське гальмо
     </li>
     <li class="product-content__description-item">Стан:  хороший
     </li>
   </ul>
 </div>
</div>
