

<header class="app-header">

  <div class="app-header__burger" (click)="menuToggle()" [class.opened]="showMenu" #burger>
    <span class="app-header__burger-item"></span>
    <span class="app-header__burger-item"></span>
    <span class="app-header__burger-item"></span>
  </div>

  <nav class="app-nav" #navEl>
    <div class="app-header-logo">

      <a href="/" class="app-header-logo__link">
        <img src="/assets/images/logo.png" alt="logo" class="app-header-logo__image">
      </a>

      <div class="app-header-logo__info">
       <h1 class="app-header-logo__headline">VMV-TRANS</h1>
        <span class="app-header-logo__text">Ваш надійний партнер</span>
      </div>

      <span class="app-header-logo__flags">
        <img src="/assets/images/ukr.png" alt="ukr" class="flags__ukr">
        <img src="/assets/images/eu.png" alt="eu" class="flags__eu">
      </span>

    </div>

    <div class="app-nav__info">

      <div class="app-nav__numbers">
        <a href="tel:+38 (032) 241-80-28" class="app-nav__numbers__item"><img class="app-nav__numbers__item-image" src="/assets/icons/phone.svg" alt="phone">+38 (032) 241-80-28</a>
        <a href="tel:+38 (032) 245-89-31" class="app-nav__numbers__item"><img class="app-nav__numbers__item-image" src="/assets/icons/phone.svg" alt="phone">+38 (032) 245-89-31</a>
        <a href="tel:+38 (095) 841-58-981" class="app-nav__numbers__item"><img class="app-nav__numbers__item-image" src="/assets/icons/viber.svg" alt="phone">+38 (095) 841-58-98</a>
      </div>

      <div class="app-nav__menu">
        <a href="#main-link" class="app-nav__menu-item app-nav__menu-item--active">Головна</a>
        <a href="#about-link" class="app-nav__menu-item">Про Нас</a>
        <a href="#fleet-link" class="app-nav__menu-item">Автопрак</a>
        <!-- <a routerLink="product-list" class="app-nav__menu-item">Продаж</a> -->
        <!-- <a href="#feedbks-link" class="app-nav__menu-item">Відгуки</a> -->
        <a href="#contacts-link" class="app-nav__menu-item">Контакти</a>
      </div>
    </div>
  </nav>

</header>

<div class="menu-mobile" [class.active]="showMenu">
  <a href="#main-link" class="menu-mobile__item">Головна</a>
  <a href="#about-link" class="menu-mobile__item">Про Нас</a>
  <a href="#fleet-link" class="menu-mobile__item">Автопрак</a>
  <!-- <a routerLink="product-list" class="menu-mobile__item">Продаж</a>
  <a href="#feedbks-link" class="menu-mobile__item">Відгуки</a> -->
  <a href="#contacts-link" class="menu-mobile__item">Контакти</a>
</div>



