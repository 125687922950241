import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductCardComponent } from './components/product-card/product-card.component';
import {ProductListComponent} from "./components/product-list/product-list.component";
import {MainContentComponent} from "./components/main/main-content/main-content.component";

const routes: Routes = [
  { path: '', component: MainContentComponent },
  { path: 'product-list', component: ProductListComponent },
  { path: 'product-card', component: ProductCardComponent },
  { path: '**', component: MainContentComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
