import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width: '100%',
        height: '610px',
        imageAutoPlay: false,
        imageAutoPlayInterval: 8000,
        imageInfinityMove: true,
        imageBullets: true,
        imageArrows: false,
        imageSwipe: true,
        imageDescription: true,
        preview: true,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      {
        breakpoint: 500,
        height: '350px',
        thumbnails:false
      },
      {
        breakpoint: 400,
        height: '230px',
        thumbnails:false
      }
    ];

    this.galleryImages = [
      {
        small: '../assets/images/magnum.png',
        medium: '../assets/images/magnum.png',
        big: '../assets/images/magnum.png',
      },
      {
        small: '../assets/images/magnum2.jpg',
        medium: '../assets/images/magnum2.jpg',
        big: '../assets/images/magnum2.jpg'
      },
      {
        small: '../assets/images/magnum3.jpg',
        medium: '../assets/images/magnum3.jpg',
        big: '../assets/images/magnum3.jpg'
      },
      {
        small: '../assets/images/magnum4.jpg',
        medium: '../assets/images/magnum4.jpg',
        big: '../assets/images/magnum4.jpg'
      },
      {
        small: '../assets/images/magnum5.jpg',
        medium: '../assets/images/magnum5.jpg',
        big: '../assets/images/magnum5.jpg'
      },
    ];
  }


}
