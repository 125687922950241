import {Component, HostListener, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  showMenu: boolean = false;
  @ViewChild('navEl') navigation;
  @ViewChild('burger') burgerItem;
  @ViewChild('sliding') slidingMenu;

  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    const clickedInside = this.navigation.nativeElement.contains(targetElement) || this.burgerItem.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showMenu = false;
    }
  }
  constructor() { }


  menuToggle() {
    this.showMenu = !this.showMenu;
  }
  ngOnInit(): void {
  }

}
