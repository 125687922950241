<app-header #header></app-header>

<div >
  <div class="app-main__overlay" *ngIf="header.showMenu"></div>
  <router-outlet> </router-outlet>
  <app-footer></app-footer>
</div>



