<footer id="contacts-link"  class="app-footer">

  <div class="app-footer__contacts-mobile"  [class.hidenContacts]="hideContacts">
    <div class="app-footer__content-address">
      <p class="app-footer__content-address__item">
        <img src="/assets/icons/map-point.svg" alt="" class="app-footer__content-address__item-image">
        Львівська обл.
        с.Пасіки-Зубрицькі
        вул.Дорожна 52, 81137
      </p>
    </div>

    <div class="app-footer__content-contacts">

      <a href="tel:+38 (032) 241-80-28" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/phone.svg" alt="phone">+38 (032) 241-80-28</a>
      <a href="tel:+38 (032) 245-89-31" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/phone.svg" alt="phone">+38 (032) 245-89-31</a>
      <a href="tel:+38 (032) 245-89-31" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/viber.svg" alt="phone">+38 (095) 841-58-98</a>

      <a href="callto://" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/skype.svg" alt="skype">vmvlviv.skype</a>
      <br>
      <a href="maillto://" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/mail.svg" alt="email">vmvlviv@ukr.net</a>
    </div>
  </div>
  <span class="app-footer__contacts-btn" (click)="showContacts()">Контакти</span>
  <div class="app-footer-logo">

    <a href="/" class="app-footer-logo__link">
      <img src="/assets/images/logo.png" alt="logo" class="app-footer-logo__image">
    </a>

    <div class="app-footer-logo__info">
      <h1 class="app-footer-logo__headline">VMV-TRANS</h1>
      <span class="app-footer-logo__text">Ваш надійний партнер</span>
    </div>

    <span class="app-footer-logo__flags">
      <img src="/assets/images/ukr.png" alt="ukr" class="flags__ukr">
      <img src="/assets/images/eu.png" alt="eu" class="flags__eu">
    </span>
  </div>


  <div class="app-nav__menu app-nav__menu--footer">
    <a href="#main-link" class="app-nav__menu-item app-nav__menu-item">Головна</a>
    <a href="#about-link" class="app-nav__menu-item">Про Нас</a>
    <a href="#fleet-link" class="app-nav__menu-item">Автопрак</a>
    <!-- <a routerLink="product-list" class="app-nav__menu-item">Продаж</a> -->
    <!-- <a href="#feedbks-link" class="app-nav__menu-item">Відгуки</a> -->
    <a href="#contacts-link" class="app-nav__menu-item">Контакти</a>
  </div>

  <div class="app-footer__content">
    <div class="app-footer__content-description">

      <p class="app-footer__content-description__item">Наша компанія працює на ринку автоперевезень
        вже більше 20 років.</p>
      <p class="app-footer__content-description__item">Обслуговування найвищого рівня , пунктуальність, безпека,
        конфіденційність та індивідуальний підхід до кожного клієнта.
        Нам вдалося завоювати репутацію надійного і відповідального партнера.</p>
      <p class="app-footer__content-description__item">Нашими фахівцями налагоджена система перевезення вантажів в країни Європи.</p>

    </div>
    <div class="app-footer__content-address">
      <p class="app-footer__content-address__item">
        <img src="/assets/icons/map-point.svg" alt="" class="app-footer__content-address__item-image">
        Львівська обл.
        с.Пасіки-Зубрицькі
        вул.Дорожна 52, 81137
      </p>
    </div>
    <div class="app-footer__content-contacts">


      <a href="tel:+38 (032) 241-80-28" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/phone.svg" alt="phone">+38 (032) 241-80-28</a>
      <a href="tel:+38 (032) 245-89-31" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/phone.svg" alt="phone">+38 (032) 245-89-31</a>
      <a href="tel:+38 (032) 245-89-31" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/viber.svg" alt="phone">+38 (095) 841-58-98</a>

      <a href="callto://vmvlviv.skype" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/skype.svg" alt="skype">vmvlviv.skype</a>
      <br>
      <a href="maillto://vmvlviv@ukr.net" class="app-footer__content-contacts__item"><img class="app-footer__content-contacts__item-image" src="/assets/icons/mail.svg" alt="email">vmvlviv@ukr.net</a>
    </div>
    <span class="app-footer__content-hours">
    <a href="maillto://vmvlviv@ukr.net" class="app-footer__content-hours__item"><img class="app-footer__content-hours__item-image" src="/assets/icons/clock.svg" alt="schedule">
      Пн-Пт: 08:00 – 18:00
      Сб: 08:00 – 14:00
      </a>
    </span>

  </div>

  <span class="app-footer__rights">
    <a href="/" class="app-footer__rights-link">Copyright 2003-2022(c) All right reserved</a>
  </span>


</footer>
<app-map></app-map>


