
<div class="product-list__wrapper">
  <span class="product-list__title"> Продаж</span>
  <div class="product-list__buttons">
    <a href="#" class="product-list__buttons__item"></a>
    <a href="#" class="product-list__buttons__item"></a>
  </div>
  <div class="product-list__container">

    <a routerLink="/product-card" class="product-list__container__item">
      <img class="product-list__container__item-image" src="../../../assets/images/gallery4.png" alt="product-item">
      <h2 class="product-list__container__item-headline">Силові тягачі</h2>
    </a>

    <a  routerLink="/product-card" class="product-list__container__item">
      <img class="product-list__container__item-image" src="../../../assets/images/gallery4.png" alt="product-item">
      <h2 class="product-list__container__item-headline">Напів причепи</h2>
    </a>

  </div>
</div>
