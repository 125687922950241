import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { FooterComponent } from './components/footer/footer.component';
import { MapComponent } from './components/map/map.component';
import { MainContentComponent } from './components/main/main-content/main-content.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { SliderComponentComponent } from './components/slider-component/slider-component.component';

import { ProductListComponent } from './components/product-list/product-list.component';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule} from "@angular/platform-browser";

import * as Hammer from  'hammerjs';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'pan': {
      direction: Hammer.DIRECTION_ALL
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    MapComponent,
    MainContentComponent,
    ProductCardComponent,
    SliderComponentComponent,
    ProductListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGalleryModule,
    HammerModule
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
