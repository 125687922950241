  <app-slider-component id="main-link"></app-slider-component>
<div class="app-main__content">

  <h2 id="about-link" class="app-main__content-headline">
    Про нас
  </h2>

    <div class="app-main__content-about">

        <div class="app-main__content-benefits">

          <div class="app-main__content-benefits__item">
            <h2 class="app-main__content-benefits__item-accent">
              20 років
            </h2>
            <span class="app-main__content-benefits__item-description">
                на ринку
              автоперевезень
            </span>
          </div>

          <div class="app-main__content-benefits__item">
            <h2 class="app-main__content-benefits__item-accent">Euro 5</h2>
            <h2 class="app-main__content-benefits__item-accent">Euro 6</h2>
          </div>

          <div class="app-main__content-benefits__item app-main__content-benefits__item--wider">
            <h2 class="app-main__content-benefits__item-accent">
              >100
            </h2>
            <span class="app-main__content-benefits__item-description ">
              сідлових тягачів ( MAN, Renault, DAF,)
              та напівпричепів (Wielton, Schmitz)
              в основному об’ємом 105 м3 – МЕГА
            </span>
          </div>

          <div class="app-main__content-benefits__item">
            <h2 class="app-main__content-benefits__item-accent">
              ~10
            </h2>
            <span class="app-main__content-benefits__item-description">
              сучасних бусів (Renault Master)
            </span>
          </div>

        </div>

        <p class="app-main__content-about__text">
          ПП фірма «ВМВ-Транс» є дійсним членом Асоціації міжнародних автомобільних перевізників України і використовує при здійсненні перевезень систему МДП (Carnet TIR). Перевезення здійснюються як з України в країни Європи і навпаки, так і між країнами Європи з використанням дозволів ЄКМТ.
        </p>
        <p class="app-main__content-about__text">
          Колектив ПП фірми «ВМВ-Транс» – це висококваліфіковані водії з великим стажем роботи, а також команда логістів-професіоналів, які здатні оперативно підібрати транспорт, виходячи з особливостей і розмірів вантажу, і забезпечити оптимальні маршрути перевезень для вчасної доставки вантажів. Разом – це люди, які своєю злагодженою співпрацею забезпечують транспортний сервіс найвищого рівня для задоволення потреб клієнтів.
        </p>
        <p class="app-main__content-about__text">
          Основною метою нашої компанії є здійснення якісних перевезень і доставка вантажів у вказане місце з дотриманням усіх термінів. Пунктуальність, збереження, конфіденційність, надійність - це критерії нашої роботи.
        </p>
        <p class="app-main__content-about__text">
          В підтвердження високої якості надання транспортних послуг та відповідності міжнародним вимогам та стандартам Приватне підприємство фірма «ВМВ-Транс» у 2014 році отримала сертифікат на систему управління якістю згідно системи сертифікації УкрСЕПРО. Цим сертифікатом засвідчується, що система управління якістю надання послуг внутрішніх та міжнародних перевезень, відповідає чинним в Україні нормативним документам та вимогам ДСТУ ISO 9001:2009 «Системи управління якістю. Вимоги».
        </p>

      <div class="app-main__content-about__buttons">
        <a href="/assets/download/reference.png" class="app-main__content-about__buttons-item" download="reference.png">Референт лист</a>
      </div>

      <div class="app-main__content-about__certificates">
        <img src="/assets/download/reference.png" alt="certificate-1" class="app-main__content-about__certificates-image">
        <img src="/assets/images/certificate-1.png" alt="certificate-1" class="app-main__content-about__certificates-image">
        <img src="/assets/images/certificate-2.png" alt="certificate-2" class="app-main__content-about__certificates-image">
        <img src="/assets/images/certificate-3.png" alt="certificate-3" class="app-main__content-about__certificates-image">
      </div>

    </div>


  <h2 id="fleet-link" class="app-main__content-headline">Наш Автопарк </h2>

  <div class="app-main__content-fleet">

     <div class="app-main__content-fleet__container">

        <div class="app-main__content-fleet__container__wrap">
          <p class="app-main__content-fleet__container__text">
            Наша компанія здійснює вантажні перевезення по Європі та Україні.
            Автопарк транспортної компанії «ВМВ-ТРАНС» укомплектований вантажними автомобілями, які відповідають європейським стандартам Euro 5 та Euro 6. Автопарк компанії має більше ста одиниць сідлових тягачів ( MAN, Renault, DAF,) та напівпричепів (Wielton, Schmitz) в основному об’ємом 105 м3 – МЕГА і до десяти сучасних бусів (Renault Master)
          </p>
          <p class="app-main__content-fleet__container__text">
            Весь вантажний автомобільний транспорт має сучасне оснащення, включаючи системи навігації GPS, а також регулярно проходить техобслуговування в сервісних центрах.
          </p>
          <p class="app-main__content-fleet__container__text">
            Нашими фахівцями налагоджена система перевезення вантажів в країни Європи.
          </p>
        </div>

       <div class="app-main__content-fleet__container-solidarity">
         <img src="/assets/images/europe.png" alt="europe" class="app-main__content-fleet__container-solidarity__image">
         <img src="/assets/images/ukraine.png" alt="ukraine" class="app-main__content-fleet__container-solidarity__image">
       </div>

      </div>

    <div class="app-main__content-fleet__container-gallery">
      <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
    </div>

    <!-- <h2 id="feedbks-link" class="app-main__content-headline">Відгуки </h2> -->

    <!-- <div class="app-main__content-fleet__container-feedbacks">

      <div class="app-main__content-fleet__container-feedbacks__item">
        <img src="/assets/images/it.png" alt="it logo" class="app-main__content-fleet__container-feedbacks__logo">
        <span class="app-main__content-fleet__container-feedbacks__headline">Інкол-Транс </span>
        <p class="app-main__content-fleet__container-feedbacks__text">Дякуємо фірмі ВМВ-ТРАНС надійний, професійний партнер з яким приємно працювати. Бажаємо подальшого процвітання !!!</p>
      </div>


    <div class="app-main__content-fleet__container-feedbacks__item">

        <img src="/assets/images/ltd.png" alt="ltd logo" class="app-main__content-fleet__container-feedbacks__logo">
        <span class="app-main__content-fleet__container-feedbacks__headline">ПП ЛТД </span>
        <p class="app-main__content-fleet__container-feedbacks__text">Велика подяка фірмі ВМВ-ТРАНС за відповідальний підхід до справи. Ви одна з не багатьох компаній, яка на такому рівні працює!!! Дуже велике вам</p>

    </div>

    <div class="app-main__content-fleet__container-feedbacks__item">

        <img src="/assets/images/seo.png" alt="seo logo" class="app-main__content-fleet__container-feedbacks__logo">
        <span class="app-main__content-fleet__container-feedbacks__headline">СЕО Топ </span>
        <p class="app-main__content-fleet__container-feedbacks__text">Відповідальна,оперативна робота..Роботою задоволені, рекомендуємо співпрацю</p>

    </div>

    <div class="app-main__content-fleet__container-feedbacks__item">

        <img src="/assets/images/elite.png" alt="elite logo" class="app-main__content-fleet__container-feedbacks__logo">
        <span class="app-main__content-fleet__container-feedbacks__headline">Еліт-ТЕК </span>
        <p class="app-main__content-fleet__container-feedbacks__text">Надійний Перевізник!З таким завжди приємно працювати. Я рахую , ВМВ-ТРАНС -це компетентна ,чесна та порядна фірма в своїй справі. Сподіваюсь в подальшому на співпрацю</p>

    </div>

      <div class="app-main__content-fleet__container-feedbacks__item">
        <img src="/assets/images/it.png" alt="it logo" class="app-main__content-fleet__container-feedbacks__logo">
        <span class="app-main__content-fleet__container-feedbacks__headline">Інкол-Транс </span>
        <p class="app-main__content-fleet__container-feedbacks__text">Дякуємо фірмі ВМВ-ТРАНС надійний, професійний партнер з яким приємно працювати. Бажаємо подальшого процвітання !!!</p>
      </div>


      <div class="app-main__content-fleet__container-feedbacks__item">

          <img src="/assets/images/ltd.png" alt="ltd logo" class="app-main__content-fleet__container-feedbacks__logo">
          <span class="app-main__content-fleet__container-feedbacks__headline">ПП ЛТД </span>
          <p class="app-main__content-fleet__container-feedbacks__text">Велика подяка фірмі ВМВ-ТРАНС за відповідальний підхід до справи. Ви одна з не багатьох компаній, яка на такому рівні працює!!! Дуже велике вам</p>

      </div>

      <div class="app-main__content-fleet__container-feedbacks__item">

          <img src="/assets/images/seo.png" alt="seo logo" class="app-main__content-fleet__container-feedbacks__logo">
          <span class="app-main__content-fleet__container-feedbacks__headline">СЕО Топ </span>
          <p class="app-main__content-fleet__container-feedbacks__text">Відповідальна,оперативна робота..Роботою задоволені, рекомендуємо співпрацю</p>

      </div>

      <div class="app-main__content-fleet__container-feedbacks__item">

          <img src="/assets/images/elite.png" alt="elite logo" class="app-main__content-fleet__container-feedbacks__logo">
          <span class="app-main__content-fleet__container-feedbacks__headline">Еліт-ТЕК </span>
          <p class="app-main__content-fleet__container-feedbacks__text">Надійний Перевізник!З таким завжди приємно працювати. Я рахую , ВМВ-ТРАНС -це компетентна ,чесна та порядна фірма в своїй справі. Сподіваюсь в подальшому на співпрацю</p>

      </div>

     </div> -->
    </div>
  </div>
