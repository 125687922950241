import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-slider-component',
  templateUrl: './slider-component.component.html',
  styleUrls: ['./slider-component.component.scss']
})
export class SliderComponentComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        width:'100%',
        height: '750px',
        imageAutoPlay: true,
        imageAutoPlayInterval: 5000,
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageBullets: true,
        imageArrows: true,
        imageSwipe: true,
        imageDescription: true,
        preview: false,
        thumbnails: false,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 1680
      {
        breakpoint: 1680,
        height: '750px',
      },
      // max-width 1020
      {
        breakpoint: 1020,
        height: '400px',
      },
      // max-width 400
      {
        breakpoint: 600,
        fullWidth: false,
        width:'100%'
      },
      {
        breakpoint: 400,
        height: '300px',
      }
    ];

    this.galleryImages = [
      {
        description: 'Мінжнародні <br> та внутрішні <br>  автоперевезення <br> вантажів',
        small: '../assets/images/slide2.jpg',
        medium: '../assets/images/slide2.jpg',
        big: '../assets/images/slide2.jpg',
      },
      {
        description: 'Мінжнародні <br> та внутрішні <br>  автоперевезення <br> вантажів',
        small: '../assets/images/slide0.jpg',
        medium: '../assets/images/slide0.jpg',
        big: '../assets/images/slide0.jpg'
      },
      {
        description: 'Мінжнародні <br> та внутрішні <br>  автоперевезення <br> вантажів',
        small: '../assets/images/slide3.jpg',
        medium: '../assets/images/slide3.jpg',
        big: '../assets/images/slide3.jpg'
      },
      {
        description: 'Мінжнародні <br> та внутрішні <br>  автоперевезення <br> вантажів',
        small: '../assets/images/slide4.jpg',
        medium: '../assets/images/slide4.jpg',
        big: '../assets/images/slide4.jpg'
      },
    ];
  }

}
