import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import 'hammerjs';
import {HeaderComponent} from "../header/header.component";
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {

  @ViewChild(HeaderComponent) componentHead:HeaderComponent;

  constructor() { }

  ngOnInit(): void {
  }

  swipeMenu() {
    this.componentHead.menuToggle();
  }
}
