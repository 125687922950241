import { Component, OnInit, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  constructor() { }

  ngOnInit(): void {
    this.galleryOptions = [
      {
        fullWidth: true,
        height: '800px',
        imageAutoPlayPauseOnHover: true,
        imageInfinityMove: true,
        imageBullets: true,
        thumbnailsArrows: false,
        imageSwipe: true,
        thumbnailsRows: 4,
        image: false,
        imageAnimation: NgxGalleryAnimation.Slide,
        thumbnailsColumns: 4,
      },
      {
        breakpoint: 1680,
        height: '900px',
        thumbnailsPercent: 20,
        thumbnailsMargin: 20
      },
      {
        breakpoint: 1020,
        height: '720px',
        thumbnailsPercent: 15,
        thumbnailsMargin: 15,
        thumbnailsColumns: 3
      },
      {
        breakpoint: 720,
        fullWidth: true,
        height: '720px',
        thumbnailsPercent: 15,
        thumbnailsMargin: 15,
        thumbnailMargin: 15,
        thumbnailsColumns: 2,
        thumbnailsArrows: false,
        imageSwipe: true
      },
      {
        breakpoint: 500,
        fullWidth: true,
        height: '500px',
        thumbnails: false,
        image: true,
        thumbnailsColumns: 0,
        thumbnailsRows: 0,
        imageArrows: false,
        thumbnailsArrows: false,
        imageSwipe: true
      }
    ];

    this.galleryImages = [
      {
        small: '../../assets/images/gallery0.jpg',
        medium: '../../../assets/images/gallery0.jpg',
        big: '../../../assets/images/gallery0.jpg',
      },
      {
        small: '../../assets/images/gallery2.jpg',
        medium: '../../../assets/images/gallery2.jpg',
        big: '../../../assets/images/gallery2.jpg',
      },
      {
        small: '../../assets/images/gallery3.jpg',
        medium: '../../../assets/images/gallery3.jpg',
        big: '../../../assets/images/gallery3.jpg',
      },
      {
        small: '../../assets/images/gallery4.jpg',
        medium: '../../../assets/images/gallery4.jpg',
        big: '../../../assets/images/gallery4.jpg',
      },
      {
        small: '../../assets/images/gallery5.jpg',
        medium: '../../../assets/images/gallery5.jpg',
        big: '../../../assets/images/gallery5.jpg',
      },
      {
        small: '../../assets/images/gallery6.jpg',
        medium: '../../../assets/images/gallery6.jpg',
        big: '../../../assets/images/gallery6.jpg',
      },
      {
        small: '../../assets/images/gallery7.jpg',
        medium: '../../../assets/images/gallery7.jpg',
        big: '../../../assets/images/gallery7.jpg',
      },
      {
        small: '../../assets/images/gallery8.jpg',
        medium: '../../../assets/images/gallery8.jpg',
        big: '../../../assets/images/gallery8.jpg',
      },
      {
        small: '../../assets/images/gallery9.JPG',
        medium: '../../../assets/images/gallery9.JPG',
        big: '../../../assets/images/gallery9.JPG',
      },
      {
        small: '../../assets/images/gallery10.JPG',
        medium: '../../../assets/images/gallery10.JPG',
        big: '../../../assets/images/gallery10.JPG',
      },
      {
        small: '../../assets/images/gallery11.JPG',
        medium: '../../../assets/images/gallery11.JPG',
        big: '../../../assets/images/gallery11.JPG',
      },
      {
        small: '../../assets/images/gallery12.JPG',
        medium: '../../../assets/images/gallery12.JPG',
        big: '../../../assets/images/gallery12.JPG',
      },
      {
        small: '../../assets/images/gallery13.JPG',
        medium: '../../../assets/images/gallery13.JPG',
        big: '../../../assets/images/gallery13.JPG',
      },
      {
        small: '../../assets/images/gallery14.JPG',
        medium: '../../../assets/images/gallery14.JPG',
        big: '../../../assets/images/gallery14.JPG',
      },
      {
        small: '../../assets/images/gallery15.JPG',
        medium: '../../../assets/images/gallery15.JPG',
        big: '../../../assets/images/gallery15.JPG',
      },
      {
        small: '../../assets/images/gallery16.JPG',
        medium: '../../../assets/images/gallery16.JPG',
        big: '../../../assets/images/gallery16.JPG',
      }
    ];
  }

}
